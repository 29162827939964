//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    headline: {
      type: String,
      default: null
    }
  }
})
