import { render, staticRenderFns } from "./BasePage.vue?vue&type=template&id=05486f95&scoped=true&"
import script from "./BasePage.vue?vue&type=script&lang=js&"
export * from "./BasePage.vue?vue&type=script&lang=js&"
import style0 from "./BasePage.vue?vue&type=style&index=0&id=05486f95&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05486f95",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NuxtDynamic: require('/builds/reifen_gundlach/frontend/node_modules/@blokwise/dynamic/src/components/Dynamic.vue').default,G4THeadline: require('/builds/reifen_gundlach/frontend/components/atoms/G4THeadline/G4THeadline.vue').default,NotificationCenter: require('/builds/reifen_gundlach/frontend/components/organisms/NotificationCenter/NotificationCenter.vue').default})
