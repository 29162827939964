import { onMounted, ref, useContext } from '@nuxtjs/composition-api'
import useCurrentLanguage from '~/composables/useCurrentLanguage'
import useFaroIntegration from '~/composables/useFaroIntegration'

const notifications = ref([])
const notificationTypes = ref(null)
let initialFetch = true

export function useNotifications () {
  const { $cms } = useContext()
  const { currentLanguage } = useCurrentLanguage()
  const { faroError } = useFaroIntegration()

  onMounted(() => init())

  function init () {
    if (initialFetch) {
      getNotificationTypes()
      initialFetch = false
    }
  }

  function removeNotification (id) {
    setTimeout(() => {
      notifications.value = notifications.value.filter(noti => noti.id !== id)
    }, 700) // time for noti-leave animation
  }

  function addNotification (notification, err) {
    if (notificationTypes.value) {
      const entry = notificationTypes.value[err ? 'error' : notification]
      if (entry) {
        const newEntry = {
          ...entry,
          id: Date.now() + Math.floor(Math.random() * 100)
        }
        notifications.value.push(newEntry)
      }
    }
  }

  async function getNotificationTypes () {
    if (!notificationTypes.value) {
      try {
        const reply = await $cms.query({
          slug: '/',
          options: {
            starts_with: currentLanguage.value !== 'en' ? `${currentLanguage.value}/notifications/` : 'notifications/',
            per_page: 100
          }
        })
        notificationTypes.value = reply.data.reduce((acc, item) => ({
          ...acc,
          [item.name]: {
            name: item.name,
            title: item.content.title,
            text: item.content.text,
            timeout: item.content.timeout,
            icon: item.content.icon
          }
        }), {})
      } catch (err) {
        faroError(err, 'c-notifications-getNotificationTypes')
      }
    }
  }

  return {
    notifications,
    removeNotification,
    addNotification
  }
}
