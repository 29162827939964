
import { defineComponent, onMounted } from '@nuxtjs/composition-api'
import useCurrentLanguage from '~/composables/useCurrentLanguage'

export default defineComponent({
  setup () {
    const { currentLanguage } = useCurrentLanguage()

    onMounted(() => {
      init()
    })

    async function init () {
      let retries = 15
      while (!window.UC_UI || !window.UC_UI.isInitialized()) {
        if (!retries) {
          // eslint-disable-next-line no-console
          console.warn('Usercentrics failed to load in time', 'color:red')
          return
        }
        await new Promise(resolve => setTimeout(resolve, 333))
        retries -= 1
      }
      UC_UI.updateLanguage(currentLanguage.value)
    }

    return {}
  }
})
