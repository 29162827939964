import { useContext } from '@nuxtjs/composition-api'

const usePageMeta = ({
  content,
  titleTemplate,
  defaultSlug,
  translatedSlugs,
  baseUrl,
  routeFullPath,
  $imageService,
  defaultLanguageCode
}) => {
  const getMetaData = () => {
    const { languageWhitelist } = useContext().$config

    const { title } = content.value
    const { description, canonical } = content.value.metadata?.[0] ?? {}
    const meta = []
    const link = []

    meta.push({ hid: 'description', name: 'description', content: description })

    const metadata = content.value.metadata?.[0] ?? {}
    addOpenGraph({ meta, metadata, baseUrl, $imageService, title })
    addTwitter({ meta, metadata, baseUrl, $imageService, title })
    addRobots({ meta, metadata })
    addCanonical({ link, canonical, baseUrl, routeFullPath })
    addHreflang({ link, defaultSlug, translatedSlugs, baseUrl, defaultLanguageCode, languageWhitelist })

    return {
      title,
      titleTemplate: titleTemplate.value,
      meta,
      link
    }
  }

  function getJsonld () {
    const jsonLd = content.value?.metadata?.[0]?.jsonLd

    try {
      return jsonLd ? JSON.parse(jsonLd) : null
    } catch (e) {
      return null
    }
  }

  return {
    getMetaData,
    getJsonld
  }
}

export default usePageMeta

function getImageUrl ({ image, fallbackImageUrl, width, height, $imageService }) {
  return image && image.filename ? `https:${$imageService.getResponsiveImageUrl(image.filename, width, height, 'jpeg')}` : fallbackImageUrl
}

function addOpenGraph ({ meta, metadata, baseUrl, $imageService, title }) {
  const { ogTitle, ogDescription, ogImage, description } = metadata
  const ogFallbackImage = `${baseUrl}/og_image.png`
  const ogImageUrl = getImageUrl({
    image: ogImage,
    fallbackImageUrl: ogFallbackImage,
    width: 1200,
    height: 630,
    $imageService
  })

  meta.push({ hid: 'og:title', name: 'og:title', content: ogTitle || title })
  meta.push({ hid: 'og:description', name: 'og:description', content: ogDescription || description })
  meta.push({ hid: 'og:image', name: 'og:image', content: ogImageUrl })
}

function addTwitter ({ meta, metadata, baseUrl, $imageService, title }) {
  const { twitterTitle, twitterDescription, twitterImage, description } = metadata
  const twitterFallbackImage = `${baseUrl}/og_image.png`
  const twitterImageUrl = getImageUrl({
    image: twitterImage,
    fallbackImageUrl: twitterFallbackImage,
    width: 280,
    height: 150,
    $imageService
  })

  meta.push({ hid: 'twitter:title', name: 'twitter:title', content: twitterTitle || title })
  meta.push({ hid: 'twitter:description', name: 'twitter:description', content: twitterDescription || description })
  meta.push({ hid: 'twitter:image', name: 'twitter:image', content: twitterImageUrl })
}

function addRobots ({ meta, metadata }) {
  const { noindex, nofollow } = metadata

  const robotsContent = []
  if (noindex) {
    robotsContent.push('noindex')
  }
  if (nofollow) {
    robotsContent.push('nofollow')
  }

  if (robotsContent.length) {
    meta.push({ hid: 'robots', name: 'robots', content: robotsContent.join(',') })
  }
}

function addCanonical ({ link, canonical = '', baseUrl, routeFullPath }) {
  if (canonical === '' || canonical === '/') {
    link.push({ rel: 'canonical', href: addTrailingSlash(baseUrl + routeFullPath) })
    return
  }

  if (canonical.startsWith('/')) {
    link.push({ rel: 'canonical', href: addTrailingSlash(baseUrl + canonical) })
  } else {
    link.push({ rel: 'canonical', href: addTrailingSlash(canonical) })
  }
}

function addTrailingSlash (href) {
  if (!href.endsWith('/')) {
    return `${href}/`
  }

  return href
}

function addHreflang ({ link, defaultSlug, translatedSlugs = [], baseUrl, defaultLanguageCode, languageWhitelist = [] }) {
  if (!Array.isArray(translatedSlugs) || !translatedSlugs?.length) {
    return
  }

  for (const { lang, path } of translatedSlugs) {
    if (!languageWhitelist.includes(lang)) { continue }
    link.push({ rel: 'alternate', hreflang: lang, href: `${baseUrl}/${lang}/${path}/` })
  }

  link.push({ rel: 'alternate', hreflang: 'x-default', href: `${baseUrl}/${defaultLanguageCode}/${defaultSlug}/` })
}
