//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import { useNotifications } from '~/composables/useNotifications'

export default defineComponent({
  props: {
    notification: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const { removeNotification } = useNotifications()
    const removeNoti = ref(false)

    onMounted(() => {
      if (props.notification.timeout && props.notification.timeout > 0) {
        setTimeout(() => {
          closeNotiWindow()
        }, props.notification.timeout * 1000)
      }
    })

    function closeNotiWindow () {
      removeNoti.value = true
      removeNotification(props.notification.id)
    }

    return { removeNotification, removeNoti, closeNotiWindow }
  }
})
