//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, toRefs, useRoute, useContext, inject, provide, ref, computed, onMounted } from '@nuxtjs/composition-api'
import useTemplating from '~/composables/useTemplating'
import usePageMeta from '~/composables/usePageMeta'
import usePublishedContent from '~/composables/usePublishedContent'
import UsercentricsMixin from '~/mixins/UsercentricsMixin.vue'
import useRedirectUrl from '~/composables/useRedirectUrl'

export default defineComponent({
  name: 'BasePage',
  mixins: [UsercentricsMixin],
  props: {
    content: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    const { $imageService, $config, $cms } = useContext()
    const route = useRoute()
    const routeFullPath = route.value.fullPath
    const { baseUrl } = $config
    const { defaultLanguageCode } = $cms
    const translatedSlugs = inject('getTranslatedSlugs', () => ['/'])()
    const defaultSlug = inject('getDefaultFullSlug', () => '/')()
    const { content } = toRefs(props)
    const { top, header, body, bottom, footer, hasCustomHeader, hasCustomFooter, titleTemplate } = useTemplating(content)
    const { getMetaData, getJsonld } = usePageMeta({
      content,
      titleTemplate,
      defaultSlug,
      translatedSlugs,
      baseUrl,
      routeFullPath,
      $imageService,
      defaultLanguageCode
    })
    const { checkLoginState } = usePublishedContent()
    const metaData = getMetaData()
    const showBackdrop = ref(false)
    const { hasExternalRedirect, routeToRedirectUrl } = useRedirectUrl()

    provide('showBackdrop', showBackdrop)

    const isH1 = computed(() => {
      return !content.value.headline
    })

    onMounted(() => {
      if (hasExternalRedirect.value) {
        routeToRedirectUrl({ external: true })
      }
    })

    return {
      top,
      header,
      body,
      bottom,
      footer,
      hasCustomHeader,
      hasCustomFooter,
      metaData,
      checkLoginState,
      getJsonld,
      showBackdrop,
      isH1
    }
  },
  head () {
    return this.metaData
  },
  jsonld () {
    return this.getJsonld()
  }
})
