//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import { useNotifications } from '~/composables/useNotifications'

export default defineComponent({
  setup () {
    const { notifications } = useNotifications()
    return { notifications }
  }
})
