//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    content: {
      type: Object,
      default: () => {}
    }
  }
})
