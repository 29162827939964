import useLoginInformation from '~/composables/useLoginInformation'

const usePublishedContent = () => {
  const { userIsLoggedIn } = useLoginInformation()

  function setPublishedContent (content) {
    return content.filter(item => checkReleaseState(item))
  }

  function checkReleaseState ({ releaseDate, endDate }) {
    if (releaseDate && endDate) {
      return checkReleaseDate(releaseDate) && checkEndDate(endDate)
    }
    if (releaseDate) {
      return checkReleaseDate(releaseDate)
    }
    if (endDate) {
      return checkEndDate(endDate)
    }
    return true
  }

  function checkReleaseDate (releaseDate) {
    const today = new Date()
    const date = new Date(releaseDate)
    return (today - date) > 0
  }

  function checkEndDate (endDate) {
    const today = new Date()
    const date = new Date(endDate)
    return (date - today) > 0
  }

  function checkLoginState (element) {
    if (element?.visibility) {
      return element.visibility !== (userIsLoggedIn.value ? 'logged-out' : 'logged-in')
    } else {
      return true
    }
  }

  return {
    setPublishedContent,
    checkReleaseState,
    checkLoginState
  }
}
export default usePublishedContent
